import clsx from "clsx";
import { ReactNode } from "react";
import Icon from "@/components/elements/Icon";
import * as styles from "./styles.module.scss";

interface TagProps {
  type?: LabelType;
  text?: string;
  className?: string;
  withHover?: boolean;
  size?: "small";
  onClick?: () => void;
  icon?: ReactNode;
}

const icons = {
  award: <Icon name="studentMortarBoard" />,
  event: <Icon name="calender" />,
  review: <Icon name="star" />,
  research: <Icon name="pencil" />,
  expert: <Icon name="expert" />,
  sustainability: <Icon name="sustainability" />,
  grant: <Icon name="studentMortarBoard" />,
  afreport: <Icon name="leafTag" />,
};

const tagLabel = {
  award: "Award",
  event: "Event",
  review: "Review",
  research: "Original research",
  expert: "Expert opinion",
  sustainability: "Sustainability",
  grant: "Grant",
  afreport: "AF report",
};

export function Tag({
  type,
  text,
  className = "",
  onClick,
  size,
  withHover,
  icon,
}: TagProps) {
  const targetedIcon = type && icons[type] ? icons[type] : null;
  const tagText = type && tagLabel[type] ? tagLabel[type] : text;
  const isClickable = !!onClick || withHover;

  if (!type && !text) return null;

  return (
    <div
      className={clsx(
        styles.tag,
        {
          [styles.withIcon]: Boolean(type && icons[type]) || Boolean(icon),
          [styles.clickableTag]: isClickable,
          [styles.small]: size === "small",
        },
        className
      )}
      onClick={onClick}
      role="none"
    >
      <div className={styles.iconWrapper}>{targetedIcon || icon}</div>
      {tagText}
    </div>
  );
}
