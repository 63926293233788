import clsx from "clsx";
import * as styles from "./styles.module.scss";

// Icons
export type IconName =
  | "mail"
  | "arrowUpRight"
  | "arrowDown"
  | "building"
  | "calender"
  | "calendar"
  | "check"
  | "close"
  | "linkedin"
  | "location"
  | "pencil"
  | "play"
  | "search"
  | "star"
  | "leaf"
  | "arrowLeft"
  | "cocoa"
  | "expert"
  | "tocArrow"
  | "research_gate"
  | "sustainability"
  | "studentMortarBoard"
  | "cacao_pod"
  | "close-x"
  | "alpro-adornment"
  | "download"
  | "refresh"
  | "lock"
  | "find"
  | "book"
  | "newsletter"
  | "success"
  | "leafTag"
  | "green-leaf"
  | "bean"
  | "chevron"
  | "fan"
  | "traced";

export function Icon({
  name,
  className,
}: {
  name: IconName;
  className?: string;
}) {
  if (name === "research_gate") {
    return (
      <div className={styles.rgIcon}>
        <img
          src={`/svgs/${name}.svg`}
          data-svg="true"
          className={clsx(styles.svg, className)}
          alt={name}
        />
      </div>
    );
  }

  return (
    <img
      src={`/svgs/${name}.svg`}
      data-svg="true"
      className={clsx(styles.svg, className)}
      alt={name}
    />
  );
}
